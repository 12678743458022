import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`The startscreen`}</h4>
    <p>{`The trailguide start screen shows an overview of Europe.`}</p>
    <br />
    <p>{`The numbers in the circles are the number of tracks in the area.`}</p>
    <br />
    <p>{`You can zoom the map by clicking the circles, using pinch gestures, clicking
the plus and minus buttons, or double clicking the map.`}</p>
    <br />
    <p>{`The default content category is "MTB singletracks".`}</p>
    <Image src="features/startscreen3.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Trails`}</h4>
    <p>{`When you zoom in you start seeing individual tracks.`}</p>
    <br />
    <p>{`The start of the track is marked with a trailhead and a play icon.`}</p>
    <br />
    <p>{`The color of a track indicate its technical difficulty.`}</p>
    <br />
    <p>{`Green is easy, blue intermediate, red difficult, and black is very difficult.`}</p>
    <Image src="features/maptrails.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Infobar`}</h4>
    <p>{`Click on a trail to open the info bar.`}</p>
    <br />
    <p>{`The info bar shows the name, length, ascent, descend and portions of the
description of the track.`}</p>
    <br />
    <p>{`The rather strange top of the info bar is the height profile. Move your finger
along the height profile to see the same point on the map. This is a nice
way to indicate the direction of the track.`}</p>
    <br />
    <p>{`Click on the info bar to open the activity page with detailed information.`}</p>
    <br />
    <Link to="/features/activitypage" mdxType="Link">
  Read more about the activity page here
    </Link>
    <Image src="features/mapinfobar.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/installation" mdxType="Link">
  ← Installation
    </Link>
    <Link className="float-right" to="/features/list" mdxType="Link">
  List →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      